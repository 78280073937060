.homeMainImgDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 136vh;
    background-image: url('../images//Homepage2.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    flex-direction: column;
}



.homeMainImgSupport {
    width: 100%;
    height: 136vh;
    display: flex;
    justify-content: end;
    align-items: start;
    position: absolute;
    cursor: pointer;
    /* background-color: aqua; */
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Apply animation to the image */
.supportAnimation {
    animation: fadeIn 1s ease-out forwards;
}



.homeViewMore {
    width: 103px;
    height: 30px;
    border-radius: 7px;
    background: rgb(16, 16, 16);
    color: rgb(255, 255, 255);
    font-family: "Poppins", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* bottom: 60%; */
    margin-right: 46%;
    cursor: pointer;
    position: relative;
    animation: fadeIn 1s ease-out forwards;

}

.homeViewMore:hover {
    color: rgb(255, 154, 0);
}

.homeContentMainDiv {
    width: 100%;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.homeLogoDiv {
    width: 100%;
    height: 45vh;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.homeLogoDivSub {
    width: 90%;
    height: 60%;
    /* background-color: beige; */
    display: flex;
    flex-direction: row;
    gap: 3%;
}


.homeLogoDivSubCol1 {
    width: 20%;
    height: 100%;

    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    gap: 5%;
}



.homeCol1Ecclipse {
    width: 101px;
    height: 101px;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeCol1Span {
    color: rgb(0, 0, 0);
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0%;
    text-align: center;
}

.homeWhyfazcoDiv {
    width: 90%;
    height: 72vh;
    /* background-color: wheat; */

}

.homeWhyfazcoDivSub {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5%;
}


.homeWhyfazcoHead {
    width: 214px;
    height: 48px;
    color: rgb(0, 0, 0);
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0%;
    /* text-align: left; */
}

.homeWhyfazcoHeadSub {
    width: 1232px;
    height: 90px;
    color: rgb(0, 0, 0);
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0%;
    text-align: center;
}

.homeWhyfazcoDivSubImg {
    height: 60%;
    width: 28%;
    /* background-color: yellow; */
}

.homeBrandsMainDiv {
    width: 90%;
    height: 70vh;
    /* background-color: rgb(218, 145, 9); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeBrandsMainDivNew {
    width: 90%;
    height: 45vh;
    /* background-color: rgb(121, 90, 32); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeBrandsMainDivSub {
    width: 100%;
    height: 90%;
    display: flex;
    /* background-color: yellowgreen; */
    flex-direction: column;
    gap: 5%;
}

.homeBrandsMainDivSubNew {
    width: 100%;
    height: 100%;
    display: flex;
    /* background-color: yellowgreen; */
    flex-direction: column;
    gap: 5%;
}

.homeBrandsHead {
    color: rgb(0, 0, 0);
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    letter-spacing: 0%;
}

.homeBrandsTable {
    width: 100%;
    height: 100%;
    /* background-color: azure; */
    display: flex;
    flex-direction: column;
    gap: 5%;
}

.homeBrandsTable2 {
    width: 100%;
    height: 70%;
    /* background-color: azure; */
    display: flex;
    flex-direction: column;
    gap: 5%;
}

.homeBrandsTableRow1 {
    width: 100%;
    height: 15%;
    /* background-color: rgb(37, 125, 202); */
    display: flex;
    flex-direction: row;
    gap: 5%;
}

.homeBrandsTableRow1New {
    width: 100%;
    height: 33%;
    /* background-color: rgb(37, 125, 202); */
    display: flex;
    flex-direction: row;
    gap: 5%;
}

.homeBrandsTable2Row1 {
    width: 100%;
    height: 28%;
    /* background-color: rgb(37, 125, 202); */
    display: flex;
    flex-direction: row;
    gap: 5%;
}

.homeBrandsTableRow2 {
    width: 100%;
    height: 35%;
    /* background-color: rgb(37, 125, 202); */
    display: flex;
    flex-direction: row;
    gap: 2%;

}

.homeBrandsTableRow2American {
    width: 100%;
    height: 25%;
    /* background-color: rgb(37, 125, 202); */
    display: flex;
    flex-direction: row;
    gap: 2%;

}

.homeBrandsTableRow2New {
    width: 100%;
    height: 75%;
    /* background-color: rgb(37, 125, 202); */
    display: flex;
    flex-direction: row;
    gap: 2%;

}

.homeBrandsTable2Row2 {
    width: 100%;
    height: 65%;
    /* background-color: rgb(37, 125, 202); */
    display: flex;
    flex-direction: row;
    gap: 2%;

}

.homeBrandsTableRow1Divs {
    height: 100%;
    width: 30%;
    box-sizing: border-box;
    border: 1px solid rgb(191, 191, 191);
    border-radius: 5px;
    color: rgb(0, 0, 0);
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 36px;
    letter-spacing: 0%;
    cursor: pointer;

}

/* Define the active class */
.active {
    background: rgb(255, 154, 0);
}


.homeBrandsTableRow2Divs {
    height: 100%;
    width: 15%;
    box-sizing: border-box;
    border: 1px solid rgb(191, 191, 191);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeBrandsPic {
    width: 60%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: yellow; */
    flex-direction: column;
}

.imgSpan {
    color: rgb(0, 0, 0);
    font-family: "Poppins", sans-serif;

}

.imgSpan2 {
    color: rgb(0, 0, 0);
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0%;
    white-space: NOWRAP;
}

.homeAfterMarketDiv {
    width: 90%;
    height: 53vh;
    /* background-color: rgb(218, 145, 9); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2%;
    align-items: center;
}

.homeAfterMarketSub {
    width: 100%;
    height: 100%;
    display: flex;
    /* background-color: yellowgreen; */
    flex-direction: column;
    align-items: start;
    gap: 5%;
}

.homeAfterMarketHead {
    color: rgb(0, 0, 0);
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0%;
    text-align: left;
}

.homeAfterMarketTable {
    width: 100%;
    height: 100%;
    /* background-color: rgb(37, 125, 202); */
    display: flex;
    flex-direction: column;
    gap: 5%;
}

.homeAfterMarketSeeAll {
    height: 6%;
    width: 100%;
    /* box-sizing: border-box; */
    /* border: 1px solid rgb(191, 191, 191); */
    /* border-radius: 5px; */
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 0.3%;
    cursor: pointer;

}

.homeAfterMarketSeeAllSpan:hover {
    color: rgb(255, 154, 0);
}

.homeAfterMarketSeeAllSpan {
    color: rgb(0, 0, 0);
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0%;
}

.homeFreeDiv {
    width: 100%;
    height: 10vh;
    /* background-color: aqua; */
}

@media only screen and (max-width: 767px) {
    .homeCol1Span {
        font-size: 7px;
    }

    .homeCol1Ecclipse {
        width: 50px;
        height: 50px;
    }

    .homeCol1EcclipseCircle {
        width: 60%;
        height: 60%;
    }

    .homeWhyfazcoHeadSub {
        font-size: 10px;
        width: 300px;
        height: 150px;
    }

    .homeBrandsTableRow1Divs {
        font-size: 8px;
    }

    .homeBrandsPicImg {
        width: 70%;
        height: 40%;
    }

    .imgSpan {
        font-size: 8px;
    }

    .imgSpan2 {
        font-size: 6px;
    }

    .homeMainImgDiv {
        height: 300px;
    }

    .homeMainImgSupport {
        height: 300px;

        /* background-color: aqua; */
    }

    .homeWhyfazcoDivSubImg {
        width: 201px;
        height: 209px;
        /* background-color: yellow; */
    }

    .homeViewMore {
        width: 44px;
        height: 10px;
        font-size: 5px;

    }


}

@media only screen and (min-width: 768px) and (max-width: 1024px) {


    .homeCol1Span {
        font-size: 15px;
    }

    .homeCol1Ecclipse {
        width: 70px;
        height: 70px;
    }

    .homeCol1EcclipseCircle {
        width: 70%;
        height: 70%;
    }

    .homeWhyfazcoHeadSub {
        font-size: 15px;
        width: 600px;
        height: 150px;
    }

    .homeBrandsTableRow1Divs {
        font-size: 15px;
    }

    .homeBrandsPicImg {
        width: 80%;
        height: 50%;
    }

    .imgSpan {
        font-size: 12px;
    }

    .imgSpan2 {
        font-size: 12px;
    }

    .homeMainImgDiv {
        height: 500px;
    }

    .homeMainImgSupport {
        height: 500px;

        /* background-color: aqua; */
    }

    .homeWhyfazcoDivSubImg {
        width: 301px;
        height: 309px;
        /* background-color: yellow; */
    }

    .homeViewMore {
        width: 54px;
        height: 14px;
        font-size: 7px;

    }
}