.signupMainDivSub {
    width: 60%;
    height: 80%;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    border: 1px solid white;

    justify-content: center;
    align-items: center;
    gap: 2%;
}

.signupMainDivSubRow {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    gap: 2%;

    /* justify-content: center; */
    align-items: center;
}

.signupMainDivSubRow1 {
    width: 50%;
    height: 100%;
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    gap: 12%;
    /* border: 1px solid white; */
    /* justify-content: center; */
    align-items: center;
}

.signupMainDivSubCol {
    width: 95%;
    height: 12%;

    display: flex;
}

.signupMainDivSubColInput {
    /* background: transparent; */
    border: 1px solid white;
    color: black;
    text-indent: 10px;
    border-radius: 5px;
    height: 100%;
    width: 100%;
}

.signUpButtonDiv {
    /* background-color: blue; */
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signUpButton {
    width: 20%;
    height: 50%;
    border-radius: 5px;
    background: rgb(255, 154, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 36px;
    cursor: pointer;
    letter-spacing: 0%;
}

@media only screen and (max-width: 767px) {
    .signUpButton {
        font-size: 7px;
        line-height: 10px;

    }

    .signupMainDivSub {
        width: 80%;
    }

}

@media only screen and (max-width: 1024px) {
    .signUpButton {
        font-size: 15px;
    }

    .signupMainDivSub {
        width: 80%;
    }
}