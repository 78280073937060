.ampHead{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f2f2f2;
}

.ampHeadSub{
    width: 85%;
    height: 130vh;
    display: flex;
    align-items: start;
    gap: 4%;
    flex-direction: column;
}


.ampHeadRoute{
    color: rgb(0, 0, 0);
font-family: "Poppins", sans-serif;
font-size: 14px;
font-weight: 500;
line-height: 17px;
letter-spacing: 0%;
text-align: left;
}

.ampHeadRouteSpan{
    cursor: pointer;

}
.ampHeadRouteSpan:hover{
    color: rgb(255, 154, 0);
}


.ampTableDiv{
    width: 100%;
    height: 130vh;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    gap: 2%;


}

.ampTableDivRow1{
width: 100%;
height: 12%;
/* background-color: bisque; */
display: flex;
flex-direction: row;
gap: 2%;
}
.ampTableDivRow1Col{
    width: 20%;
height: 100%;
    box-sizing: border-box;
border: 1px solid rgb(191, 191, 191);
border-radius: 5px;
display: flex;
justify-content: center;
align-items: center;
}
.ampTableDivRow1ColSpan{
   color: rgb(0, 0, 0);
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0%;
}
@media only screen and (max-width: 767px) {
    .ampTableDivRow1ColSpan{
        font-size: 6px;
    }
    .ampTableDivRow1Col{
     
    height: 70%;
     
    }
    .ampTableDiv{
        height: 100vh;
      
        gap: 1%;
    
    
    }

}
@media only screen and (min-width: 768px) and (max-width: 1024px) {

    .ampTableDivRow1ColSpan{
        font-size: 12px;

    }

    .ampTableDivRow1Col{
     
        height: 90%;
         
        }
   

}
  