.privacy_root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.privacy_root>div {
  margin-top: 3%;
}

.privacyContent {
  width: 85%;
  height: 80%;
}

.privacy_title {
  width: 100%;
  height: 2.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0%;
  text-align: left;
  margin-top: 0.5%;
}

.privacyContent section {
  color: rgb(0, 0, 0);
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0%;
  text-align: left;
}

.privacyContent label {
  color: rgb(0, 0, 0);
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0%;
  text-align: left;
}

.privacyContent span {
  color: rgb(0, 8, 179);
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0%;
  text-align: left;
}

.privacyContentFooter {
  width: 92%;
  height: 250px;
  /* margin-top: 1.5%; */
  /* margin-bottom: 1.5%; */
  background: rgba(232, 232, 232, 0.71);
  display: flex;
  align-items: center;
  justify-content: center;

}

.privacyContentFooterdiv {
  width: 90%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: aqua; */
}

.privacyContentFooterdivpart {
  width: 33.30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2%;
  /* background-color: beige; */
}

.divseperation {
  width: 0;
  height: 80%;
  border: 1px solid rgb(175, 175, 175);
}

.HaveQues {
  color: rgb(14, 99, 214);
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0%;
  text-align: left;
}

.getintouch {
  color: rgb(0, 0, 0);
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0%;
  text-align: left;
}

.mobilenum {
  color: rgb(0, 0, 0);
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0%;
  text-align: left;
}

.privacyEmail {
  color: rgb(0, 0, 0);
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0%;
  text-align: left;
}

.privacyEmailaddress {
  color: rgb(14, 99, 214);
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0%;
  text-align: left;
}

.privacyfollowus {
  color: rgb(0, 0, 0);
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0%;


}

.socialiconsdiv {
  width: 50%;
  height: 30%;
  display: flex;
  /* background-color: aqua; */
  justify-content: center;
  align-items: center;
}

.socialiconspart {
  width: 25%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.socialicons {
  width: 30px;
  height: 30px;
  background: rgb(14, 99, 214);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* For screens smaller than 768px (tablets) */
@media only screen and (max-width: 768px) {
  .privacy_root {
    height: auto;
  }

  .privacyContent {
    height: auto;
  }

  .privacy_title {
    font-size: 30px;
    line-height: 36px;
  }

  .privacyContent section,
  .privacyContent label,
  .privacyContent span {
    font-size: 16px;
    line-height: 24px;
  }

  .privacyContentFooter {
    height: auto;
  }

  .privacyContentFooterdiv {
    flex-direction: column;
    gap: 10px;
  }

  .privacyContentFooterdivpart {
    width: 100%;
    text-align: center;
  }

  .divseperation {
    display: none;
  }

  .socialiconsdiv {
    width: 100%;
    justify-content: space-around;
  }
}

/* For screens smaller than 480px (phones) */
@media only screen and (max-width: 480px) {

  .privacyContent label,
  .privacyContent span {
    font-size: 14px;
    line-height: 20px;
  }

  .mobilenum {
    font-size: 20px;
    line-height: 24px;
  }

  .privacyContentFooter {
    padding: 10px;
  }

  .socialiconsdiv {
    height: auto;
  }

  .socialiconspart {
    width: 25%;
    height: auto;
    margin: 0 auto;
  }
}