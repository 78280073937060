.mainDiv {
    display: flex;
    flex-direction: column;
    
    /* position: relative; */
}

.Navbar {
    width: 100%;
    height: 83px;
    /* position: sticky;
    top: 0;
    z-index: 1000; 
    background-color: #ffffff; */
}


.topBar {
    width: 100%;
    height: 52px;
    background: rgb(255, 154, 0);
    display: flex;
    justify-content: center;
    align-items: center;

}


.topBarSub {
    width: 85%;
    height: 100%;
    /* background-color: blue; */
    display: flex;
    /* align-items: center; */
    flex-direction: row;
    

}
.topBarSubImg{
    width: 40%;
    height: 100%;
    /* background-color: bisque; */
    display: flex;
    align-items: center;

}
.topBarSubImgPic{
    width: 18px;
height: 12.85px;
cursor: pointer;
}
.topBarSubHead{
    /* display: flex; */
    width: 20%;
    height: 100%;
    /* background-color: rgb(169, 132, 87); */
    display: flex;
    align-items: center;
    justify-content: center;
    /* justify-content: center; */
    /* background-color: aqua; */
    /* align-items: center; */
    color: rgb(0, 0, 0);
font-family: "Inter", sans-serif;
font-size: 16px;
font-weight: 600;
line-height: 19px;
letter-spacing: 0%;
/* text-align: left; */
text-transform: uppercase;
}
.topBarSubHeadFree{
    width: 40%;
    height: 100%;
    /* background-color: rgb(187, 112, 20); */
    display: flex;
    align-items: center;
}

.priceUploadMainDiv {
    position: relative;
    /* Add position relative for positioning pseudo-element */
    width: 100%;
    height: 126.60028449502134vh;
    /* Subtracting the combined heights of Navbar and topBar */
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('https://i.postimg.cc/NF6g12xX/image-491.png');
    background-size: 250px;
    background-repeat: repeat;
}


.priceUploadDivBorder {
    width: 85%;
    height: 85%;
    /* background-color: blue; */
    box-sizing: border-box;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    gap: 8%;
}
@media only screen and (max-width: 767px) {
    .priceUploadDivBorder{
        gap:3%;
    }
}

.priceUploadDivBorderRow1 {
    height: 100%;
    width: 50%;
    /* background-color: beige; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.priceUploadDivBorderRow2 {
    height: 100%;
    width: 50%;
    /* background-color: turquoise; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.priceUploadDivBorderCol1 {
    height: 88%;
    width: 90%;
    /* background-color: rgb(126, 189, 143); */
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 6.5%;

}
.priceUploadDivBorderColContent{
    display: flex;
    /* height: 100%; */
    width: 100%;
    flex-direction: column;
    align-items: start;
}

.priceUploadDivBorderCol1Label {
    color: rgb(0, 0, 0);
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0%;
    text-align: left;
    text-transform: uppercase;
}

.priceUploadDivBorderCol1Input{
    width: 100%;
    height: 32px;
    box-sizing: border-box;
    border: 2px solid rgb(0, 0, 0);
}
.priceUploadDivBorderCol1InputCombobox{
   
    box-sizing: border-box;
    border: 2px solid rgb(0, 0, 0);
   
}
.priceUploadDivBorderCol1InputCombobox2{
    width: 100%;
    height: 32px;
}


.priceUploadDivBorderColContentButton{
    width: 35%;
height: 56px;
border-radius: 10px;
background: rgb(255, 154, 0);
color: rgb(0, 0, 0);
display: flex;
align-items: center;
justify-content: center;
font-family: "Poppins", sans-serif;
font-size: 20px;
font-weight: 500;
line-height: 30px;
letter-spacing: 0%;
text-align: left;
text-transform: uppercase;
cursor: pointer;
}

.priceUploadDivBorderCol2 {
    height: 88%;
    width: 90%;
    /* background-color: rgb(181, 181, 242); */
    
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 6.5%;

}

.priceUploadDivBorderCol1Input.focused {
    /* Define your focused styles here */
    background-color:rgb(235, 234, 232); /* For example, change border color when focused */
    /* Add other styles as needed */
}

.custom-dropdown {
    /* position: relative; */
    width: 100%;
}

.custom-dropdown.focused {
    /* Define your focused styles here */
    background-color:rgb(235, 234, 232); /* For example, change border color when focused */
    /* Add other styles as needed */
}

@media only screen and (max-width: 767px) {

    .topBarSubHead {
        font-size: 6px; /* Reduce font size for better readability */
        font-weight: bolder;
        white-space: nowrap;

    }
    .priceUploadDivBorderCol1Label{
        font-size: 6px;
        font-weight: bold;

    }
    .priceUploadDivBorderColContentButton{
        font-size: 6px;
        font-weight: bolder;
    }
}

