.startMainDiv{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.startMainDivSub{
    width:85%;
    height: auto;
    /* background-color: antiquewhite; */
    display: flex;
    text-align: start;
    flex-direction: column;
}
.startMainDivRoute{
    color: rgb(0, 0, 0);
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0%;
    text-align: left;
}
.startMainDivSub > div,
.startMainDivSub > span {
  margin-bottom: 3%; /* Add margin-bottom to create a gap between each element */
}
.startHeadDiv{
    color: rgb(0, 0, 0);
font-family:"Poppins", sans-serif ;
font-size: 24px;
font-weight: 600;
line-height: 30px;
letter-spacing: 0%;
text-align: center;
}

.startDiv{
    display: flex;
    flex-direction: column;
}

.startHeadSub{
    color: rgb(0, 0, 0);
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0%;
}

.startContentSub{
    color: rgb(0, 0, 0);
font-family: "Poppins", sans-serif;
font-size: 20px;
font-weight: 500;
line-height: 150%;
letter-spacing: 0%;
text-align: left;
}
.startMessageDiv{
    width: 100%;
    height: auto;
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.startMessageDivSub{
    width: 770px;
height: 631px;
box-sizing: border-box;
border: 1px solid rgb(0, 0, 0);
border-radius: 10px;
background: rgb(255, 255, 255);
display: flex;
justify-content: center;
align-items: center;
}
.startMessageDivSubBorder{
  width: 90%;
  height: 90%;
  /* background-color: beige; */
  /* gap: 10%; */
}
.startMessageHead{
    color: rgb(0, 0, 0);
font-family: "Poppins", sans-serif;
font-size: 24px;
font-weight: 600;
line-height: 40px;
letter-spacing: 0%;
display: flex;
justify-content: center;
align-items: center;
}
.startMessageInput{
    width: 100%;
    height: 64px;
    box-sizing: border-box;
border: 1px solid rgb(148, 148, 148);
border-radius: 5px;
text-indent: 2%;
}
.startMessageDivSubBorder > div,
.startMessageDivSubBorder > span {
  margin-bottom: 4%; /* Add margin-bottom to create a gap between each element */
}

.startMessageForm{
    margin-bottom: 4%; /* Add margin-bottom to create a gap between each element */

}

.startMessageInput2{
    width: 100%;
    height: 162px;
    box-sizing: border-box;
border: 1px solid rgb(148, 148, 148);
border-radius: 5px;
text-indent: 2%;
}
.startMessageButton{
    width: 180px;
height: 44px;
border-radius: 5px;
background: rgb(255, 154, 0);
color: rgb(0, 0, 0);
font-family: "Poppins", sans-serif;
font-size: 20px;
font-weight: 600;
line-height: 36px;
letter-spacing: 0%;
text-align: left;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
}

.startMessageButton:hover{
color: white;
}

@media only screen and (max-width: 767px) {

    .startHeadDiv{
      
    font-size: 18px;
   
    }
    .startHeadSub{
        
        font-size: 17px;
      
    }
    .startContentSub{
       
    font-size: 15px;
  
    }
    .startMessageHead{
      
    font-size: 18px;
   
    }
    .startMessageButton{
        width: 140px;
    height: 34px;
    
    font-size: 17px;
   
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {


}
