.footerMainDiv {
    width: 100%;
    height: 45.945945945945944vh;
    background: rgb(36, 36, 36);
    display: flex;
    justify-content: center;
    align-items: center;
}

.footerMainDivSub {
    width: 88%;
    height: 85%;
    /* background-color: aqua; */
    display: flex;
    flex-direction: row;
}

.footerMainDivSubCol1 {
    width: 25%;
    height: 100%;
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    gap: 8%;
}

.footerMainDivSubCol2 {
    width: 25%;
    height: 100%;
    /* background-color: rgb(22, 87, 48); */
    display: flex;
    flex-direction: column;
    align-items: center;

}

.footerMainDivSubCol3 {
    width: 25%;
    height: 100%;
    /* background-color: rgb(115, 50, 226); */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footerMainDivSubCol4 {
    width: 25%;
    height: 100%;
    /* background-color: rgb(179, 64, 74); */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footerMainDivSubCol1Logo {
    width: 27%;
    height: 23%;
    /* background-color: beige; */
}

.footerMainDivSubCol1Content {
    color: rgb(255, 255, 255);
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0%;
    text-align: left;
}

.footerMainDivSubCol1Ecllipse {
    height: 4.978662873399715vh;
    width: 10vw;
    /* background-color: yellowgreen; */
    display: flex;
    flex-direction: row;
    gap: 15%;

}

.footerMainDivSubCol1Ecllipse1 {
    height: 100%;
    width: 25%;
    /* background-color: white; */
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid rgb(203, 197, 197);
    display: flex;
    justify-content: center;
    align-items: center;

}

.footerMainDivSubCol1Ecllipse1:hover {
    background-color: #346DBC;
    border: none;
}

.footerMainDivSubCol1Ecllipse2 {
    height: 100%;
    width: 25%;
    /* background-color: white; */
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid rgb(203, 197, 197);
    display: flex;
    justify-content: center;
    align-items: center;

}

.footerMainDivSubCol1Ecllipse2:hover {
    background-color: #DD2A7B;
    border: none;

}

.footerMainDivSubCol1Ecllipse3 {
    height: 100%;
    width: 25%;
    /* background-color: white; */
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid rgb(203, 197, 197);
    display: flex;
    justify-content: center;
    align-items: center;

}

.footerMainDivSubCol1Ecllipse3:hover {
    background-color: red;
    border: none;

}

.footerMainDivSubCol2SubHead {
    height: 100%;
    width: 50%;
    /* background-color: rgb(94, 213, 51);   */
    display: flex;
    flex-direction: column;
    gap: 9%;
}


.footerMainDivSubCol2Head {
    color: rgb(255, 255, 255);
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0%;
    text-align: left;
}

.footerMainDivSubCol2Sub {
    color: rgb(255, 255, 255);
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0%;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;

}

.footerMainDivSubCol2Sub:hover {
    color: rgb(255, 154, 0);
}

@media only screen and (max-width: 767px) {
    .footerMainDivSub {
        width: 95%;
    }

    .footerMainDivSubCol1Content {
        font-size: 6px;
    }

    .footerMainDivSubCol2Head {
        font-size: 10px;
        white-space: nowrap;


    }

    .footerMainDivSubCol2Sub {
        font-size: 7px;
        white-space: nowrap;
    }

    .footerMainDivSubCol1Logo {
        width: 45%;
        height: 15%;
    }

    .footerMainDivSubCol1Ecllipse1 {
        height: 70%;
        width: 70%;
    }

    .footerMainDivSubCol1Ecllipse2 {
        height: 70%;
        width: 70%;
    }

    .footerMainDivSubCol1Ecllipse3 {
        height: 70%;
        width: 70%;
    }
.imageFooterView{
    height: 95%;
    width: 95;
}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .footerMainDivSub {
        width: 95%;
    }

    .footerMainDivSubCol1Content {
        font-size: 8px;
    }

    .footerMainDivSubCol2Head {
        font-size: 12px;
        white-space: nowrap;


    }

    .footerMainDivSubCol2Sub {
        font-size: 10px;
        white-space: nowrap;
    }

    .footerMainDivSubCol1Logo {
        width: 45%;
        height: 15%;
    }

    .footerMainDivSubCol1Ecllipse1 {
        height: 70%;
        width: 70%;
    }

    .footerMainDivSubCol1Ecllipse2 {
        height: 70%;
        width: 70%;
    }

    .footerMainDivSubCol1Ecllipse3 {
        height: 70%;
        width: 70%;
    }
.imageFooterView{
    height: 95%;
    width: 95;
}
}