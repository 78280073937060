.supportMainDiv{
    width: 50%;
height: auto;

/* background-color: aqua; */
display: flex;
justify-content: center;
align-items: center;
/* border-radius: 10px; */
background: rgb(255, 255, 255);
border-bottom-left-radius: 25px;

}

.supportMainDivSub{
    width: 90%;
height: 90%;
box-sizing: border-box;
/* border: 1px solid rgb(0, 0, 0); */
/* border-radius: 5px; */
display: flex;
flex-direction: column;
align-items: center;
}
.supportMainDivSub > div,
.supportMainDivSub > span {
  margin-bottom: 3%; /* Add margin-bottom to create a gap between each element */
}

.supportClose{
    display: flex;
    justify-content: end;
    align-items: end;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    color: red;
    cursor: pointer;
}

.supportMainHead{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.supportMainHeadImg{
    width: 70px;
height: 40.55px;
/* background-color: aqua; */
}
.supportMainHeadSpan{
    color: rgb(0, 0, 0);
font-family: "Poppins", sans-serif;
font-size: 32px;
font-weight: 600;
line-height: 48px;
letter-spacing: 0%;
text-align: left;
}
.supportMainSubHead{
    color: rgb(0, 0, 0);
font-family: "Poppins", sans-serif;
font-size: 24px;
font-weight: 500;
line-height: 36px;
letter-spacing: 0%;
text-align: center;
}
.supportMainColorSub{
    color: rgb(255, 154, 0);
font-family: "Poppins", sans-serif;
font-size: 20px;
font-weight: 600;
line-height: 30px;
letter-spacing: 0%;
text-align: center;
}
.SupportFillDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

}
.SupportFillDiv > div,
.SupportFillDiv > span {
  margin-bottom: 2%; /* Add margin-bottom to create a gap between each element */
}
.SupportFillDivRow1 {
    display: flex;
    height: 28px;
    width: 100%;
    flex-direction: row; /* Maintain row-wise alignment */
    align-items: center;
    gap: 3%; /* Adjust the gap between elements */
    /* background-color: bisque; */
}

.SupportFillDivRow2 {
    display: flex;
    height: 68px;
    width: 100%;
    flex-direction: row; /* Maintain row-wise alignment */
    align-items: center;
    gap: 3%; /* Adjust the gap between elements */
    /* background-color: bisque; */
}

.SupportFillDivRow1Span{
    color: rgb(0, 0, 0);
font-family: "Poppins", sans-serif;
width: 30%;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0%;
text-align: start;
display: flex;
align-items: start;
justify-content: start;
white-space: nowrap;
}
.SupportFillDivRow1Input{
    width: 550px;
height: 28px;
text-indent: 2%;
/* background-color: aqua; */
}
.SupportFillDivRow2Input{
    width: 550px;
height: 68px;
text-indent: 2%;
/* background-color: aqua; */
}

.supportButtonDiv{
    width: 172px;
height: 40px;
border-radius: 7px;
background: rgb(75, 191, 110);
color: rgb(255, 255, 255);
font-family: "Poppins", sans-serif;
font-size: 20px;
font-weight: 600;
line-height: 30px;
letter-spacing: 0%;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
}

.supportButtonDiv:hover{
    color: rgb(255, 154, 0);
}


@media only screen and (max-width: 767px) {

    .supportMainDiv{
        width: 60%;
    }
    
    .supportMainHeadSpan{
        font-size: 15px;
    }
    .supportMainSubHead{
        font-size: 10px;
    }
    .supportMainColorSub{
        font-size: 8px;
    }
    .SupportFillDivRow1 {
        height: 28px;
        width: 100%;
        
    }
    .SupportFillDivRow1Span{
        font-size: 7px;
        width: 50%;
    }
    .SupportFillDivRow1Input{
        width: 50%;
        height: 18px;
    }
    .supportButtonDiv{
        width: 82px;
    height: 20px;
    
    font-size: 8px;
   
    }
    .SupportFillDivRow2 {
        height: 48px;
        width: 100%;
        
    }
    .SupportFillDivRow2Input{
        width: 50%;
        height: 48px;
    }
    

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .supportMainDiv{
        width: 60%;
    }
    
    .supportMainHeadSpan{
        font-size: 15px;
    }
    .supportMainSubHead{
        font-size: 10px;
    }
    .supportMainColorSub{
        font-size: 8px;
    }
    .SupportFillDivRow1 {
        height: 28px;
        width: 100%;
        
    }
    .SupportFillDivRow2 {
        height: 48px;
        width: 100%;
        
    }

    .SupportFillDivRow1Span{
        font-size: 7px;
        width: 50%;
    }

   

    .SupportFillDivRow1Input{
        width: 50%;
        height: 18px;
    }

    .SupportFillDivRow2Input{
        width: 50%;
        height: 48px;
    }

    .supportButtonDiv{
        width: 82px;
    height: 20px;
    
    font-size: 8px;
   
    }
    
}