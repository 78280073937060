.aboutMainDiv{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.aboutMainDivSub{
    width:85%;
    height: auto;
    /* background-color: aqua; */
   
}
.aboutMainDivSec1{
    height: auto;
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 5%;
}
.aboutMainDivSec1 > div,
.aboutMainDivSec1 > span {
  margin-bottom: 3%; /* Add margin-bottom to create a gap between each element */
}

.aboutMainDivRoute{
    color: rgb(0, 0, 0);
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0%;
    text-align: left;
}
.aboutMainDivHead{
    color: rgb(0, 0, 0);
font-family: "Poppins", sans-serif;
font-size: 32px;
font-weight: 600;
line-height: 48px;
letter-spacing: 0%;
text-align: left;
}
.aboutMainDivHeadContent{
    color: rgb(0, 0, 0);
font-family: "Poppins", sans-serif;
font-size: 20px;
font-weight: 500;
line-height: 37px;
letter-spacing: 0%;
text-align: left;
}
.aboutMainImageDiv{
    height: 100%;
    width: 100%;
    /* background-color: bisque; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.aboutMainImageDivSub{
    height: 100%;
    width: 30%;
    /* background-color: yellow; */
}
.aboutMainDivSec2{
    height: auto;
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 5%;
    /* background-color: aqua; */
    /* margin-top: 5%; */
}

.aboutMainDivSec2 > div,
.aboutMainDivSec2 > span {
  margin-bottom: 3%; /* Add margin-bottom to create a gap between each element */
}


.aboutMainDivHeadContentDiv{
    display: flex;
    flex-direction: column;
}
.aboutMainDivSec3{
    height: auto;
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 5%;
    /* background-color: aqua; */
    /* margin-top: 5%; */
}

.aboutMainDivSec3 > div,
.aboutMainDivSec3 > span {
  margin-bottom: 3%; /* Add margin-bottom to create a gap between each element */
}


.aboutMainDivSec4{
    height: auto;
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    gap: 5%;
    /* background-color: aqua; */
    /* margin-top: 5%; */
}

.aboutMainDivSec4 > div,
.aboutMainDivSec4 > span {
  margin-bottom: 3%; /* Add margin-bottom to create a gap between each element */
}


.aboutMainDivHeadContent2{
    color: rgb(0, 0, 0);
font-family: "Poppins", sans-serif;
font-size: 20px;
font-weight: 600;
line-height: 40px;
letter-spacing: 0%;
text-align: left;
}
.aboutMainDivHead2{
    color: rgb(0, 0, 0);
font-family: "Poppins", sans-serif;
font-size: 25px;
font-weight: 600;
line-height: 38px;
letter-spacing: 0%;
text-align: left;
}
.dotHeadAbout{
    color: rgb(0, 0, 0);
font-family: "Poppins", sans-serif;
font-size: 48px;
font-weight: 600;
line-height: 40px;
letter-spacing: 0%;
text-align: left;
}

@media only screen and (max-width: 767px) {
    .aboutMainDivHead{
        font-size: 20px;
    }
.aboutMainDivHeadContent{
    font-size: 15px;
}
.aboutMainDivHeadContent2{
    font-size: 16px;
}
.aboutMainDivHead2{
    font-size: 19px;
}

}
@media only screen and (min-width: 768px) and (max-width: 1024px) {


}