
.mainDiv2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
}

.tableTopBar {
    width: 100%;
    height: 162px;
    background: rgb(255, 154, 0);
    display: flex;
    justify-content: center;
    align-items: center;

}


.tableTopBarSub{
    width: 85%;
    height: 100%;
    /* background-color: blue; */
    display: flex;
    /* align-items: center; */
    flex-direction: row;
    gap: 2%;
}

.tableTopBarSubImg{
    width: 15%;
    height: 100%;
    /* background-color: bisque; */
    display: flex;
    align-items: center;

}
.tableTopBarSubImgPic{
    width: 22px;
height: 16.85px;
cursor: pointer;
}

.tableSearchDiv{
    width: 60%;
    height: 100%;
    /* background-color: rgb(50, 225, 59); */
    display: flex;
        align-items: center;
}

.tableSearchDivInput{
    width: 100%;
height: 53px;
    border-radius: 3px;
background: rgb(255, 255, 255);
border: none;
text-indent: 2%;
}

.tableButtonDiv{
  height: 100%;
  width: 25%;
  /* background-color: violet; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.tableButtonDivSub{
    width: 95%;
height: 53px; 
border-radius: 3px;
background: rgb(0, 0, 0);
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
cursor: pointer;
}

.tableButtonDivSubSpan{
    color: rgb(255, 255, 255);
font-family: "Poppins", sans-serif;
font-size: 20px;
font-weight: 500;
line-height: 30px;
letter-spacing: 0%;
margin-left: 3%;
}

.tableButtonDivSubSpan:hover{
    color: rgb(255, 154, 0);
}

.tablePageMainDiv{
    position: relative;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    /* background-color: #881d1d; */
}
.tablePageMainDiv > div{
    /* margin-top: 1%; */
}
.tablePageMainDivSub{
    padding-top: 2%;
    width: 95%;
    height: auto;
/* background-color: aqua; */
display: flex;
/* justify-content: center; */
align-items: start;
background-color: white;
}


.tableTable{
width: 100%;
height: auto;
border: 1px solid black;
/* background-color: rgb(47, 119, 11); */
}

.tableTable tr {
    height: 40px; /* Adjust the height as needed */
}

.tablePageHead{
 width: 100%;
    height:45px;
    background: rgb(255, 154, 0);
}
.tablePageTr{
    color: rgb(0, 0, 0);
font-family:  "Poppins", sans-serif;
font-size: 14px;
font-weight: 700;
line-height: 21px;
letter-spacing: 0%;
text-align: left;
text-indent: 2%;

}
.tableEvenRow {
    background: rgb(230, 229, 229); 
    color: rgb(0, 0, 0);
font-family:  "Poppins", sans-serif;
font-size: 13px;
font-weight:550;
line-height: 21px;
letter-spacing: 0%;
text-align: left;
text-indent: 2%;

 }

 .quantity-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
 }
  
  .tableOddRow {
    background: rgba(192, 192, 192, 1);
    color: rgb(0, 0, 0);
font-family:  "Poppins", sans-serif;
font-size: 13px;
font-weight: 550;
line-height: 21px;
letter-spacing: 0%;
text-align: left;
text-indent: 2%;


  }

  .tableMailDiv{
    /* background-color: aqua; */
    width: 100%;
    /* height: 100%; */
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tableMainDivButton{
    border-radius: 5px;
    width: 100%;
    height: 53px;
    background: red;
    height: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0%;
    cursor: pointer;

  }

  .tableMainDivButton:hover{
    color:black;
  }
 .tableSignUp{
    width: 100%;
    /* background-color: aqua; */
    /* height: 100%; */
    text-align: end;
    color: black;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0%;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: underline; /* Add this line */
 }
 .tableSignUp:hover{
   color: white;
 }
  
@media only screen and (max-width: 767px) {
    .tablePageTr{
        font-size: 6px;
    }
    .tableEvenRow{
        font-size: 6px;
    } 
    .tableOddRow{
        font-size: 6px;
    }
    .tableButtonDivSubSpan{
display: none;        
    }
    .quantity-container{
        /* background-color: aqua; */
        width: 3%;
    }
    .quantity-container > button{
/* background-color: yellow; */
width: 20%;
display: flex;
justify-content: center;
align-items: center;
font-size: xx-small;
    }
    .tableMainDivButton{
        font-size: 7px;
    }

    .tableSignUp{
       
        font-size: 8px;
        
     }
     .deleteicon{
        height: 10%;
     }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .tablePageTr{
        font-size: 8px;
    }
    .tableEvenRow{
        font-size: 8px;
    } 
    .tableOddRow{
        font-size: 8px;
    }
    .tableButtonDivSubSpan{
        display: none;        
            }
    .tableMainDivButton{
        font-size: 12px;
    }
  }
  