.navbarMainDiv {
    /* Rectangle 4 */
    position: absolute;
    width: 100%;
    height: 83px;
    background: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
}


.navbarMainDivSub {
    height: 100%;
    width: 85%;
    /* background-color: yellow; */
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
}

.navbarLogo {
    /* position: absolute; */
    width: 20%;
    height: 100%;
    /* background-color: bisque; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
}


.navbarLinks {
    height: 100%;
    width: 60%;
    /* background-color: violet; */
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    gap: 15%;
}

.navbarLinksSpan {
    color: rgb(255, 255, 255);
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0%;
    cursor: pointer;
    white-space: nowrap;

    /* text-align: left; */
}
.navbarLinksSpan:hover{
    color: rgb(255, 154, 0);
}

.navbarButton {
    /* position: absolute; */
    width: 20%;
    height: 40px;
    /* background-color:red; */
    display: flex;
    justify-content: end;

    /* padding: 8px 20px 8px 20px; */
}

.navbarButtonSub {
    height: 100%;
    width: 60%;
    /* background-color: yellowgreen; */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0%;
    /* text-align: left; */
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 48px;
    backdrop-filter: blur(36px);
}
.navbarButtonSub:hover{
   color: rgb(255, 154, 0);
}

@media only screen and (max-width: 767px) {

    .navbarLinksSpan,
    .navbarButtonSub {
        font-size: 6px; /* Reduce font size for better readability */
        font-weight: bold;
        white-space: nowrap;

    }
    .navbarLinks{
        gap: 10%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .navbarLinksSpan,
    .navbarButtonSub {
        font-size: 13px; /* Reduce font size for better readability */
        font-weight: bold;
        white-space: nowrap;
    }
    .navbarLinks {
        gap: 8%;
    }
}
