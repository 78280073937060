/* Drawer.css */
/* Drawer.css */
.drawerMainDivMob {
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 60vh;
    background: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* Keep existing styles */
     z-index: 5000;
    /* Add animation-related styles */
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-fill-mode: forwards; /* Retains the styles of the last keyframe after the animation ends */
  }
  
  @keyframes slideIn {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes slideOut {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .drawerMainDivMob.open {
    animation-name: slideIn; /* Apply slideIn animation */
  }
  
  .drawerMainDivMob.close {
    animation-name: slideOut; /* Apply slideOut animation */
  }
  
  
.drawerMainOptionsMob{
    /* position: absolute; */
    width: 100%;
    height: 45vh;
    /* background: rgb(255, 154, 0); */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5%;

   
}
.drawerMainOptions1{
    width: 70%;
height: 42px;
border-radius: 23px;
cursor: pointer;
background: rgb(255, 154, 0);
display: flex;
align-items: center;
justify-content: center;
}
.drawerMainOptions1Inner{
    height: 100%;
    width: 80%;
    /* background-color: tomato; */
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.drawerMainOptions1Span{
 width: 90%;
 /* background-color: blue;   */
color: rgb(0, 0, 0);
font-family: "Inter", sans-serif;
font-size: 14px;
font-weight: 600;
line-height: 18px;
letter-spacing: 0%;
/* text-align: left; */
text-transform: uppercase;
display: flex;
align-items: center;
justify-content: start;
}
.drawerMainOptions1Arrow{
    /* position: relative; */
    width: 10%;
    display: flex;
    align-items: end;
    justify-content: end;
    
}
.drawerMainOptions2{
    width: 70%;
height: 42px;
border-radius: 23px;
cursor: pointer;

background: rgb(255, 154, 0);
display: flex;
align-items: center;
justify-content: center;

}
.drawerMainOptions2Inner{
    height: 100%;
    width: 80%;
    /* background-color: tomato; */
    display: flex;
    align-items: center;
    /* justify-content: center; */
}
.drawerMainOptions2Span{
 width: 90%;
 /* background-color: blue;   */
color: rgb(0, 0, 0);
font-family: "Inter", sans-serif;
font-size: 14px;
font-weight: 600;
line-height: 18px;
letter-spacing: 0%;
/* text-align: left; */
text-transform: uppercase;
display: flex;
align-items: center;
justify-content: start;
}
.drawerMainOptions2Arrow{
    /* position: relative; */
    width: 10%;
    display: flex;
    align-items: end;
    justify-content: end;
    
}
.drawerMainOptions3{
    width: 70%;
height: 42px;
border-radius: 23px;
cursor: pointer;

background: rgb(255, 154, 0);
display: flex;
align-items: center;
justify-content: center;

}
.drawerMainOptions3Inner{
    height: 100%;
    width: 80%;
    /* background-color: tomato; */
    display: flex;
    align-items: center;
    /* justify-content: center; */
}
.drawerMainOptions3Span{
 width: 90%;
 /* background-color: blue;   */
color: rgb(0, 0, 0);
font-family: "Inter", sans-serif;
font-size: 14px;
font-weight: 600;
line-height: 18px;
letter-spacing: 0%;
/* text-align: left; */
text-transform: uppercase;
display: flex;
align-items: center;
justify-content: start;
}
.drawerMainOptions3Arrow{
    /* position: relative; */
    width: 10%;
    display: flex;
    align-items: end;
    justify-content: end;
    
}
.drawerMainOptions4{
    width: 70%;
height: 42px;
border-radius: 23px;
background: rgb(255, 154, 0);
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;


}

.drawerMainOptions4Inner{
    height: 100%;
    width: 80%;
    /* background-color: tomato; */
    display: flex;
    align-items: center;
    /* justify-content: center; */
}
.drawerMainOptions4Span{
 width: 90%;
 /* background-color: blue;   */
color: rgb(0, 0, 0);
font-family: "Inter", sans-serif;
font-size: 14px;
font-weight: 600;
line-height: 18px;
letter-spacing: 0%;
/* text-align: left; */
text-transform: uppercase;
display: flex;
align-items: center;
justify-content: start;
}
.drawerMainOptions4Arrow{
    /* position: relative; */
    width: 10%;
    display: flex;
    align-items: end;
    justify-content: end;
    
}

@media only screen and (max-width: 767px) {

  .drawerMainOptions1Span{
    font-size: 10px;
    font-weight: bolder;
    font-family: 'poppins';
  }
    .drawerMainOptions2Span{
      font-size: 10px;
      font-weight: bolder;
      font-family: 'poppins';

    }
    .drawerMainOptions3Span{
      font-size: 10px;
      font-weight: bolder;
      font-family: 'poppins';

    }
    .drawerMainOptions4Span{
      font-size: 10px;
      font-weight: bolder;
      font-family: 'poppins';

    }
    .drawerMainDivMob {
      width: 50%;
    }
  
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .drawerMainOptions1Span{
    font-size: 12px;
    font-weight: bolder;
  }
    .drawerMainOptions2Span{
      font-size: 12px;
      font-weight: bolder;
    }
    .drawerMainOptions3Span{
      font-size: 12px;
      font-weight: bolder;
    }
    .drawerMainOptions4Span{
      font-size: 12px;
      font-weight: bolder;
    }
    .drawerMainDivMob {
      width: 50%;
    }
}
