.contactImgDiv {
    width: 100%;
    height: 90vh;
    position: relative;

}

.contactusSpan {
    position: absolute;
    top: 87%;
    left: 42%;
    z-index: 2;


}

.contactUsContentDiv {
    width: 100%;
    height: 80vh;
    /* background-color: yellow; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactUsContentDivSub {
    width: 87%;
    height: 80%;
    /* background-color: bisque; */
    display: flex;
    flex-direction: row;
}

.contactUsContentDivSubCol1 {
    height: 100%;
    width: 50%;
    /* background-color: violet; */
    display: flex;
    flex-direction: column;
    gap: 30%;
}

.contactUsContentDivSubCol2 {
    height: 100%;
    width: 50%;
    /* background-color: rgb(128, 238, 100); */
    display: flex;
    flex-direction: column;
    gap: 7%;
}

.contactUsContentDivSubCol1Content1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 20%;
    gap: 8%;
}

.contactUsContentDivSubCol1Content1 img {
    height: 40%;
    width: 7%;
}


.contactUsContentDivSubCol1Content1 span {
    color: rgb(0, 0, 0);
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    width: 70%;
    letter-spacing: 0%;
    text-align: left;
}

.contactUsContentDivSubCol1Content2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 20%;
}

.contactUsContentDivSubCol1Content2 img {
    height: 40%;
    width: 7%;
}

.contactUsContentDivSubCol2NameInput {
    box-sizing: border-box;
    border: 1px solid rgb(148, 148, 148);
    border-radius: 5px;
    width: 100%;
    height: 10%;
    text-indent: 2%;
}

.contactUsContentDivSubCol2MessageInput {
    box-sizing: border-box;
    border: 1px solid rgb(148, 148, 148);
    border-radius: 5px;
    width: 100%;
    height: 30%;
    text-indent: 2%;
}

.contactUsContentDivSubCol2ButtonDiv {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactUsContentDivSubCol2Button {
    border-radius: 5px;
    width: 25%;
    background: rgb(255, 154, 0);
    height: 100%;
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0%;
    cursor: pointer;
    /* text-align: left; */
}

.contactUsContentDivSubCol2Button:hover {
    color: white;
}


@media only screen and (max-width: 767px) {
    .contactusSpan {
        left: 22%;
    }

    .contactUsContentDivSubCol1Content1 span {
        font-size: 10px;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
    }

    .contactUsContentDivSubCol1Content1 img {
        height: 30%;
        width: 5;
    } 

    .contactUsContentDivSubCol1Content2 img {
        height: 30%;
        width: 5;
    }

    .contactUsContentDivSubCol2Button {
        font-size: 10px;
        width: 30%;
    }

    .contactImgDiv {
        height: 40vh;
    }

    .contactUsContentDivSub {
        /* background-color: violet; */
        width: 95%;
    }



}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .contactImgDiv {
        height: 50vh;
    }



}